<template>
  <div class="flex items-center">
    <div
      :id="id"
      class="cursor-pointer"
      :data-testid="`container-weighted-unweighted-${dataTestid}`"
    >
      <div v-if="textContent" v-html="text" />
      <div
        v-else
        class="whitespace-nowrap flex flex-col"
        :class="`tooltip-slot-container${isWeighted ? '' : '-not'}-weighted`"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted } from 'vue'
import tippy, { hideAll } from 'tippy.js'

const props = withDefaults(
  defineProps<{
    isWeighted: boolean
    textContent?: string
    dataTestid: string
    isFooter?: boolean
    hasOther?: boolean
  }>(),
  {
    isFooter: false,
    hasOther: false,
  },
)

const vm = getCurrentInstance()
const id = `tooltip-id-weighted-unweighted-${vm?.uid}`

const createTextHtml = (text, symbol) =>
  `${text}<sup class="tooltip-weigthed-symbol">${symbol}</sup>`

const symbol = computed(() => (props.isWeighted ? '*' : '†'))

const text = computed(() => {
  if (!props.textContent) {
    return
  }

  let text = props.textContent
  const searchWords = ['Supply', 'Production']

  if (props.hasOther) return `${text}`

  if (props.isFooter) return `${symbol.value}${text}`

  const regexExistWord = new RegExp(`\\b(${searchWords.join('|')})\\b`, 'i')
  const hasWord = regexExistWord.test(text)

  if (hasWord) {
    searchWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi')
      text = text.replace(regex, createTextHtml(word, symbol.value))
    })
  } else {
    text = createTextHtml(text, symbol.value)
  }

  return text
})

onMounted(() => {
  nextTick(() => {
    tippy(`#${id}`, {
      placement: 'bottom',
      theme: 'weight',
      arrow: true,
      allowHTML: true,
      onShow(instance) {
        const div = document.createElement('div')
        div.id = `tooltip-id-weighted-unweighted-content`
        div.style.zIndex = '91'
        div.setAttribute('data-testid', 'tooltip-weighted-unweighted')

        if (props.hasOther) {
          const spanTitle = document.createElement('span')
          spanTitle.setAttribute('data-testid', 'title-tooltip')
          spanTitle.style.fontWeight = '700'
          spanTitle.textContent = 'Lithium-bearing'

          const content = `“Other” refers to ${spanTitle.outerHTML} minerals or deposits not included in defined categories.`
          div.innerHTML = content
          instance.setContent(`${div.outerHTML}`)
          return
        }

        if (props.isWeighted) {
          const spanTitle = document.createElement('span')
          spanTitle.setAttribute('data-testid', 'title-tooltip')
          spanTitle.style.fontWeight = '700'
          spanTitle.textContent = 'Weighted Supply'

          const spanWeighted = document.createElement('span')
          spanWeighted.style.fontWeight = '700'
          spanWeighted.textContent = 'weighted'

          const spanProject = document.createElement('span')
          spanProject.style.fontWeight = '700'
          spanProject.textContent = 'project development status'

          const content = `${spanTitle.outerHTML} is ${spanWeighted.outerHTML} according to ${spanProject.outerHTML},
              Benchmark tracks announced projects based on the status of project finance and commissioning.
              Later in the forecast period more supply will be required from currently unknown sources.`

          div.innerHTML = content
        } else {
          const spanTitle = document.createElement('span')
          spanTitle.setAttribute('data-testid', 'title-tooltip')
          spanTitle.style.fontWeight = '700'
          spanTitle.textContent = 'Unweighted Supply'

          const content = `${spanTitle.outerHTML} as forecasted on an asset by asset basis, not accounting for project status.`

          div.innerHTML = content
        }

        instance.setContent(`${div.outerHTML}`)
      },
    })
  })
})

onBeforeUnmount(() => {
  hideAll()
})
</script>

<style scoped>
:deep(.tooltip-weigthed-symbol) {
  font-size: 0.7em;
}

:deep([class*='tooltip-slot-container-'] > *:last-child) {
  position: relative;
}

:deep(.tooltip-slot-container-not-weighted > *:last-child::after) {
  content: '†';
  font-size: 0.7em;
  position: absolute;
  top: -3px;
}

:deep(.tooltip-slot-container-weighted > *:last-child::after) {
  content: '*';
  font-size: 0.7em;
  position: absolute;
  top: -3px;
}
</style>
